import routesLik from './constants/routes.constants'
import Home from './pages/publicPages/Home'
import LoginPage from './pages/publicPages/LoginPage'
import RegisterPage from './pages/publicPages/RegisterPage'
import ProductCreate from './pages/privatePages/ProductCreate'
import WomenWardrobe from './pages/publicPages/WomenWardrobe'
import WomenWardrobeA from './pages/publicPages/WomenWardrobeA'
import WomenWardrobeDlyaBeremennyh from './pages/publicPages/WomenWardrobeDlyaBeremennyh'
import WomenWardrobeVerhnyaya from './pages/publicPages/WomenWardrobeVerhnyaya'
import ProductBuy from './pages/privatePages/ProductBuy'
import MessagesPage from './pages/privatePages/MessagesPage'
import WomenWardrobeBluzyRubashki from './pages/publicPages/WomenWardrobeBluzyRubashki'
import WomenWardrobeGolovnyeUbory from './pages/publicPages/WomenWardrobeGolovnyeUbory'
import WomenWardrobeDomashnyaya from './pages/publicPages/WomenWardrobeDomashnyaya'
import WomenWardrobeKombinezony from './pages/publicPages/WomenWardrobeKombinezony'
import WomenWardrobeKupalniki from './pages/publicPages/WomenWardrobeKupalniki'
import WomenWardrobeBeleKupalniki from './pages/publicPages/WomenWardrobeBeleKupalniki'
import WomenWardrobeObuv from './pages/publicPages/WomenWardrobeObuv'
import WomenWardrobePidzhakiKostyumy from './pages/publicPages/WomenWardrobePidzhakiKostyumy'
import WomenWardrobePlatyaYubki from './pages/publicPages/WomenWardrobePlatyaYubki'
import WomenWardrobeSviteryTolstovki from './pages/publicPages/WomenWardrobeSviteryTolstovki'
import WomenWardrobeSportivnaya from './pages/publicPages/WomenWardrobeSportivnaya'
import ProductId from './pages/publicPages/ProductId'
import WomenWardrobeFutbolkiTopy from './pages/publicPages/WomenWardrobeFutbolkiTopy'
import WomenWardrobeDzhinsyBryuki from './pages/publicPages/WomenWardrobeDzhinsyBryuki'
import WomenWardrobeDrugoe from './pages/publicPages/WomenWardrobeDrugoe'
import MenWardrobe from './pages/publicPages/MenWardrobe'
import MenWardrobeAksessuary from './pages/publicPages/MenWardrobeAksessuary'
import MenWardrobeVerhnyaya from './pages/publicPages/MenWardrobeVerhnyaya'
import MenWardrobeGolovnyeUbory from './pages/publicPages/MenWardrobeGolovnyeUbory'
import MenWardrobeDomashnyaya from './pages/publicPages/MenWardrobeDomashnyaya'
import MenWardrobeKombinezony from './pages/publicPages/MenWardrobeKombinezony'
import MenWardrobenNizhneeBelePlavki from './pages/publicPages/MenWardrobenNizhneeBelePlavki'
import MenWardrobeObuv from './pages/publicPages/MenWardrobeObuv'
import MenWardrobePidzhakiKostyumy from './pages/publicPages/MenWardrobePidzhakiKostyumy'
import MenWardrobeRubashki from './pages/publicPages/MenWardrobeRubashki'
import MenWardrobeSviteryTolstovki from './pages/publicPages/MenWardrobeSviteryTolstovki'
import MenWardrobeSpecodezhda from './pages/publicPages/MenWardrobeSpecodezhda'
import MenWardrobeSportivnaya from './pages/publicPages/MenWardrobeSportivnaya'
import MenWardrobeFutbolkiPolo from './pages/publicPages/MenWardrobeFutbolkiPolo'
import MenWardrobeDzhinsyBryuki from './pages/publicPages/MenWardrobeDzhinsyBryuki'
import MenWardrobeDrugoe from './pages/publicPages/MenWardrobeDrugoe'
import ChildrenWardrobe from './pages/publicPages/ChildrenWardrobe'
import ChildrenWardrobeAksessuary from './pages/publicPages/ChildrenWardrobeAksessuary'
import ChildrenWardrobeBluzyIRubashki from './pages/publicPages/ChildrenWardrobeBluzyIRubashki'
import ChildrenWardrobeVerhnyaya from './pages/publicPages/ChildrenWardrobeVerhnyaya'
import ChildrenWardrobeGolovnyeUbory from './pages/publicPages/ChildrenWardrobeGolovnyeUbory'

export const publicRouteConfig = [
  {
    id: 0,
    path: routesLik.root,
    component: Home,
    exact: true,
  },
  {
    id: 1,
    path: routesLik.login,
    component: LoginPage,
    exact: true,
  },
  {
    id: 2,
    path: routesLik.register,
    component: RegisterPage,
    exact: true,
  },
  {
    id: 3,
    path: routesLik.productCreate,
    component: ProductCreate,
    exact: true,
  },
  {
    id: 4,
    path: routesLik.productBuy,
    component: ProductBuy,
    exact: true,
  },
  {
    id: 5,
    path: routesLik.womenWardrobe,
    component: WomenWardrobe,
    exact: true,
  },
  {
    id: 6,
    path: routesLik.womenWardrobeA,
    component: WomenWardrobeA,
    exact: true,
  },
  {
    id: 7,
    path: routesLik.womenWardrobeAId,
    component: ProductId,
    exact: true,
  },
  {
    id: 8,
    path: routesLik.womenWardrobeBluzy,
    component: WomenWardrobeBluzyRubashki,
    exact: true,
  },
  {
    id: 9,
    path: routesLik.womenWardrobeBluzyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 10,
    path: routesLik.womenWardrobeDlyaBeremennyh,
    component: WomenWardrobeDlyaBeremennyh,
    exact: true,
  },
  {
    id: 11,
    path: routesLik.womenWardrobeDlyaBeremennyhId,
    component: ProductId,
    exact: true,
  },
  {
    id: 12,
    path: routesLik.womenWardrobeVerhnyaya,
    component: WomenWardrobeVerhnyaya,
    exact: true,
  },
  {
    id: 13,
    path: routesLik.womenWardrobeVerhnyayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 14,
    path: routesLik.womenWardrobeGolovnyeUbory,
    component: WomenWardrobeGolovnyeUbory,
    exact: true,
  },
  {
    id: 15,
    path: routesLik.womenWardrobeGolovnyeUboryId,
    component: ProductId,
    exact: true,
  },
  {
    id: 16,
    path: routesLik.womenWardrobeDomashnyaya,
    component: WomenWardrobeDomashnyaya,
    exact: true,
  },
  {
    id: 17,
    path: routesLik.womenWardrobeDomashnyayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 18,
    path: routesLik.womenWardrobeKombinezony,
    component: WomenWardrobeKombinezony,
    exact: true,
  },
  {
    id: 19,
    path: routesLik.womenWardrobeKombinezonyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 20,
    path: routesLik.womenWardrobeKupalniki,
    component: WomenWardrobeKupalniki,
    exact: true,
  },
  {
    id: 21,
    path: routesLik.womenWardrobeKupalnikiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 22,
    path: routesLik.womenWardrobeBeleKupalniki,
    component: WomenWardrobeBeleKupalniki,
    exact: true,
  },
  {
    id: 23,
    path: routesLik.womenWardrobeBeleKupalnikiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 24,
    path: routesLik.womenWardrobeObuv,
    component: WomenWardrobeObuv,
    exact: true,
  },
  {
    id: 25,
    path: routesLik.womenWardrobeObuvId,
    component: ProductId,
    exact: true,
  },
  {
    id: 26,
    path: routesLik.womenWardrobePidzhakiKostyumy,
    component: WomenWardrobePidzhakiKostyumy,
    exact: true,
  },
  {
    id: 27,
    path: routesLik.womenWardrobePidzhakiKostyumyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 28,
    path: routesLik.womenWardrobePlatyaYubki,
    component: WomenWardrobePlatyaYubki,
    exact: true,
  },
  {
    id: 29,
    path: routesLik.womenWardrobePlatyaYubkiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 30,
    path: routesLik.womenWardrobeSviteryTolstovki,
    component: WomenWardrobeSviteryTolstovki,
    exact: true,
  },
  {
    id: 31,
    path: routesLik.womenWardrobeSviteryTolstovkiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 32,
    path: routesLik.womenWardrobeSportivnaya,
    component: WomenWardrobeSportivnaya,
    exact: true,
  },
  {
    id: 33,
    path: routesLik.womenWardrobeSportivnayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 34,
    path: routesLik.womenWardrobeFutbolkiTopy,
    component: WomenWardrobeFutbolkiTopy,
    exact: true,
  },
  {
    id: 35,
    path: routesLik.womenWardrobeFutbolkiTopyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 36,
    path: routesLik.womenWardrobeDzhinsyBryuki,
    component: WomenWardrobeDzhinsyBryuki,
    exact: true,
  },
  {
    id: 37,
    path: routesLik.womenWardrobeDzhinsyBryukiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 38,
    path: routesLik.womenWardrobeDrugoe,
    component: WomenWardrobeDrugoe,
    exact: true,
  },
  {
    id: 39,
    path: routesLik.womenWardrobeDrugoeId,
    component: ProductId,
    exact: true,
  },
  {
    id: 40,
    path: routesLik.menWardrobe,
    component: MenWardrobe,
    exact: true,
  },
  {
    id: 41,
    path: routesLik.menWardrobeAksessuary,
    component: MenWardrobeAksessuary,
    exact: true,
  },
  {
    id: 42,
    path: routesLik.menWardrobeAksessuaryId,
    component: ProductId,
    exact: true,
  },
  {
    id: 43,
    path: routesLik.menWardrobeVerhnyaya,
    component: MenWardrobeVerhnyaya,
    exact: true,
  },
  {
    id: 44,
    path: routesLik.menWardrobeVerhnyayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 45,
    path: routesLik.menWardrobeGolovnyeUbory,
    component: MenWardrobeGolovnyeUbory,
    exact: true,
  },
  {
    id: 46,
    path: routesLik.menWardrobeGolovnyeUboryId,
    component: ProductId,
    exact: true,
  },
  {
    id: 47,
    path: routesLik.menWardrobeDomashnyaya,
    component: MenWardrobeDomashnyaya,
    exact: true,
  },
  {
    id: 48,
    path: routesLik.menWardrobeDomashnyayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 49,
    path: routesLik.menWardrobeKombinezony,
    component: MenWardrobeKombinezony,
    exact: true,
  },
  {
    id: 50,
    path: routesLik.menWardrobeKombinezonyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 51,
    path: routesLik.menWardrobenNizhneeBelePlavki,
    component: MenWardrobenNizhneeBelePlavki,
    exact: true,
  },
  {
    id: 52,
    path: routesLik.menWardrobeNizhneeBelePlavkiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 53,
    path: routesLik.menWardrobeObuv,
    component: MenWardrobeObuv,
    exact: true,
  },
  {
    id: 54,
    path: routesLik.menWardrobeObuvId,
    component: ProductId,
    exact: true,
  },
  {
    id: 55,
    path: routesLik.menWardrobePidzhakiKostyumy,
    component: MenWardrobePidzhakiKostyumy,
    exact: true,
  },
  {
    id: 56,
    path: routesLik.menWardrobePidzhakiKostyumyId,
    component: ProductId,
    exact: true,
  },
  {
    id: 57,
    path: routesLik.menWardrobeRubashki,
    component: MenWardrobeRubashki,
    exact: true,
  },
  {
    id: 58,
    path: routesLik.menWardrobeRubashkiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 61,
    path: routesLik.menWardrobeSpecodezhda,
    component: MenWardrobeSpecodezhda,
    exact: true,
  },
  {
    id: 62,
    path: routesLik.menWardrobeSpecodezhdaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 63,
    path: routesLik.menWardrobeSportivnaya,
    component: MenWardrobeSportivnaya,
    exact: true,
  },
  {
    id: 64,
    path: routesLik.menWardrobeSportivnayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 65,
    path: routesLik.menWardrobeFutbolkiPolo,
    component: MenWardrobeFutbolkiPolo,
    exact: true,
  },
  {
    id: 66,
    path: routesLik.menWardrobeFutbolkiPoloId,
    component: ProductId,
    exact: true,
  },
  {
    id: 67,
    path: routesLik.menWardrobeDzhinsyBryuki,
    component: MenWardrobeDzhinsyBryuki,
    exact: true,
  },
  {
    id: 68,
    path: routesLik.menWardrobeDzhinsyBryukiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 69,
    path: routesLik.menWardrobeDrugoe,
    component: MenWardrobeDrugoe,
    exact: true,
  },
  {
    id: 70,
    path: routesLik.menWardrobeDrugoeId,
    component: ProductId,
    exact: true,
  },
  //childrenWardrobe
  {
    id: 70,
    path: routesLik.childrenWardrobe,
    component: ChildrenWardrobe,
    exact: true,
  },
  {
    id: 71,
    path: routesLik.childrenWardrobeAksessuary,
    component: ChildrenWardrobeAksessuary,
    exact: true,
  },
  {
    id: 72,
    path: routesLik.childrenWardrobeAksessuaryId,
    component: ProductId,
    exact: true,
  },
  {
    id: 73,
    path: routesLik.childrenWardrobeBluzyIRubashki,
    component: ChildrenWardrobeBluzyIRubashki,
    exact: true,
  },
  {
    id: 74,
    path: routesLik.childrenWardrobeBluzyIRubashkiId,
    component: ProductId,
    exact: true,
  },
  {
    id: 75,
    path: routesLik.childrenWardrobeVerhnyaya,
    component: ChildrenWardrobeVerhnyaya,
    exact: true,
  },
  {
    id: 76,
    path: routesLik.childrenWardrobeVerhnyayaId,
    component: ProductId,
    exact: true,
  },
  {
    id: 77,
    path: routesLik.childrenWardrobeGolovnyeUbory,
    component: ChildrenWardrobeGolovnyeUbory,
    exact: true,
  },
  {
    id: 78,
    path: routesLik.childrenWardrobeGolovnyeUboryId,
    component: ProductId,
    exact: true,
  },
]

export const privateRouteConfig = [
  ...publicRouteConfig.filter((r) => r.id !== 1 && r.id !== 2),
  {
    id: 88,
    path: routesLik.productCreate,
    component: ProductCreate,
    exact: true,
  },
  {
    id: 90,
    path: routesLik.messages,
    component: MessagesPage,
    exact: true,
  },
  {
    id: 91,
    path: routesLik.messagesId,
    component: MessagesPage,
    exact: true,
  },
]
